/* Copyright 2020 Avetti.com Corporation - All Rights Reserved

This source file is subject to the Avetti Commerce Front End License (ACFEL 1.20)
that is accessible at https://www.avetticommerce.com/license */
import React from "react";

import Main from "../content/Main";

import Layout from "../other/layout";
import SEO from "../other/seo";
import Product from "../components/ACG-ProductPage/OFBProductPage";

import { Router } from "@reach/router";
import { withPrefix } from "gatsby-link";
//8
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Router>
      <Main path={withPrefix("/")} />

      <Product dynamic={true} default />
    </Router>
  </Layout>
);

export default IndexPage;
