import React from "react";

// core components
import Slider from "./Slider";

function Main(props) {
  if (props.component === "staticContent") {
    return (
      <React.Fragment>
        <div id="bd"></div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div id="bd">
          <div className="item">
            <div style={{ padding: "unset" }}>
              <div id="slide">
                <div className="slideshow-container">
                  <Slider />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Main;
