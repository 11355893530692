import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";

import "../assets/css/dcverticalmegamenu.scss";

import { LINK_DISTRIBUTION, PREVIEW } from "../project-config";

const Slider = () => {
  const data = useStaticQuery(graphql`
    query {
      img2: file(relativePath: { eq: "slider/slider6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600, maxHeight: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      allAvettiMenu {
        nodes {
          childs {
            name
            vid
            cid
            thumbnail
            image
            position
            description
            metadescription
            metakeywords
            URL
            childs {
              name
              vid
              cid
              thumbnail
              image
              position
              description
              metadescription
              metakeywords
              URL
            }
          }
        }
      }
    }
  `);

  console.info("data3", data);

  return (
    <div id="homeBanner">
      <div className="categories-mega-menu v-container">
        <h1>Categories</h1>
        <ul id="mega-1" className="mega-menu">
          {data.allAvettiMenu.nodes[0].childs.map(child => {
            const { URL, description, cid, thumbnail } = child;
            return (
              <li className="mega-menu-item" key={cid}>
                <Link to={`/${URL}`}>
                  {thumbnail ? (
                    <img
                      className="cat-image"
                      src={`${LINK_DISTRIBUTION}/store${thumbnail}`}
                      alt={"Image of " + description}
                    />
                  ) : (
                    <div className="cat-image-placeholder"></div>
                  )}

                  <span
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></span>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Slider;
